.leaflet-container {
  margin-top: 0;
  height: 100%;
  width: 100%;
  background: #0ab3e1 !important;
}

.navbar {
  box-shadow: 3px 3px 5px #0ab3e1;
  opacity: 0.8;
  font-family: "KoHo", sans-serif;
}
